<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Image</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="image-form">
          <v-file-input
            v-model="fields.image"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            label="Image"
            outlined
            background-color="white"
            @change="imageHasChanged()"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="image-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      product: {},
      fields: {
        image: null,
        image_has_changed: false,
      },
      errors: {},
    };
  },

  computed: {},

  watch: {},

  methods: {
    imageHasChanged() {
      this.fields.image_has_changed = true;
    },

    openForm: function (product, image = null) {
      if (image !== null) {
        this.isEditing = true;
        this.product = product;
        this.fields.isEditing = true;

        this.fields.image = product.image
          ? new File([product.image], product.image)
          : null;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};

      this.fields.image = null;
      this.fields.image_has_changed = false;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.fields.type = "gallery";
      payload.productId = this.product.id;

      this.$store
        .dispatch("craigtoun/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
