<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-craigtoun-products' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Products</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col cols="6" align="left">
          <h1>{{ product.name }}</h1>
        </v-col>
        <v-col cols="6" align="right">
          <v-row justify="end" no-gutters>
            <v-select
              label="Status"
              v-model="fields.status"
              :items="statuses"
              :loading="statusLoading"
              outlined
              persistent-hint
              @change="statusChanged()"
            ></v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Gallery</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.imageDialog.openForm(product)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Image</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="pb-4">
              <v-row v-if="product.main_image">
                <v-col cols="12">
                  <v-card>
                    <v-img
                      height="200"
                      :src="product.main_image.asset_urls.url"
                      aspect-ratio="1"
                      cover
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    >
                      <v-chip label class="ml-4 mb-4">Main Image</v-chip>
                    </v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        x-small
                        depressed
                        color="red lighten-4 red--text"
                        @click="openDeleteImage(product.main_image, true)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        color="blue lighten-4 blue--text"
                        @click="
                          $refs.imageDialog.openForm(
                            product,
                            product.main_image
                          )
                        "
                      >
                        <v-icon x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

              <draggable v-model="imagesOther" class="row">
                <template v-for="(image, i) in imagesOther">
                  <v-col v-bind:key="i" cols="12" sm="4" class="text-center">
                    <v-card>
                      <v-img
                        height="100"
                        :src="image.asset_urls.url"
                        aspect-ratio="1"
                        cover
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                      >
                        <v-chip label v-if="image.is_main" class="ml-4 mb-4"
                          >Main Image</v-chip
                        >
                      </v-img>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          x-small
                          depressed
                          color="red lighten-4 red--text"
                          @click="openDeleteImage(image)"
                        >
                          <v-icon x-small>mdi-archive</v-icon>
                        </v-btn>

                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          class="mr-2"
                          @click="$refs.imageDialog.openForm(product, image)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-if="!image.is_main"
                              x-small
                              depressed
                              color="orange lighten-4 orange--text"
                              @click="setMainImage(image)"
                            >
                              <v-icon x-small>mdi-image-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Set Main Image</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </draggable>
            </v-card-text>
          </v-card>

          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Options</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.assignDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Assign Options</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="pink lighten-4 pink--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.optionsDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Options</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="orange lighten-4 orange--text"
                    v-on="on"
                    @click="$refs.attributeDialog.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Attribute</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <div v-for="(options, attr) in groupedAttributes" :key="attr">
                <v-simple-table class="mb-4">
                  <tbody>
                    <tr>
                      <th>{{ attr }}</th>
                      <th>Price</th>
                      <th>Stock</th>
                      <th class="text-right">Actions</th>
                    </tr>

                    <tr v-for="option in options" :key="option.id">
                      <td>{{ option.name }}</td>
                      <td>£{{ formatPrice(option.pivot.price) }}</td>
                      <td>{{ option.pivot.stock }}</td>
                      <td class="text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              x-small
                              depressed
                              color="blue lighten-4 blue--text"
                              v-on="on"
                              class="mr-2"
                              @click="$refs.assignDialog.openForm(option)"
                            >
                              <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              x-small
                              depressed
                              color="red lighten-4 red--text"
                              v-on="on"
                              @click="openDeleteProductAttribute(option)"
                            >
                              <v-icon x-small>mdi-archive</v-icon>
                            </v-btn>
                          </template>
                          <span>Archive</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card class="mb-5">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ product.name }}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>£{{ formatPrice(product.price) }}</td>
                </tr>
                <tr>
                  <th>Category</th>
                  <td>
                    <div v-if="product.category_id">
                      {{ product.category.name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Stock</th>
                  <td>{{ product.stock }}</td>
                </tr>
                <tr>
                  <th>Slug</th>
                  <td>{{ product.slug }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-5">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Description</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <tiptap
                class="mb-5"
                v-model="fields.description"
                :laravelError="errors.description"
                @error="handleError"
              />
              <v-btn
                :loading="loading"
                align="right"
                color="accent"
                @click="update('description')"
                >Save</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>RELATED PRODUCTS</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.relatedDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <template v-for="(product, i) in product.related">
                  <v-col v-bind:key="i" cols="12" sm="4" class="text-center">
                    <v-img
                      v-if="product.main_image"
                      height="200"
                      :src="product.main_image.asset_urls.url"
                      aspect-ratio="1"
                      cover
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    >
                      <v-card-title>{{ product.name }}</v-card-title>
                    </v-img>
                    <v-img
                      v-else
                      height="200"
                      src="@/assets/placeholder.svg"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    >
                      <v-card-title>{{ product.name }}</v-card-title>
                    </v-img>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <product-dialog ref="productDialog" />
    <image-dialog ref="imageDialog" />
    <related-dialog ref="relatedDialog" />
    <detail-dialog ref="detailDialog" />
    <attribute-dialog ref="attributeDialog" />
    <options-dialog ref="optionsDialog" />
    <assign-dialog ref="assignDialog" />

    <v-dialog v-model="deleteProductOption.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Product</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteProductOption.product.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteProductOption"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteProductOption.loading"
            @click="saveDeleteProductOption"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteImage.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Image</v-card-title>
        <v-card-text>Are you sure you wish to delete image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteImage"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteImage.loading"
            @click="saveDeleteImage"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductDialog from "./components/ProductForm.vue";
import OptionsDialog from "./components/OptionsDialog.vue";
import ImageDialog from "./components/ImageDialog.vue";
import DetailDialog from "./components/DetailForm.vue";
import RelatedDialog from "./components/RelatedDialog.vue";
import AttributeDialog from "./components/AttributeDialog.vue";
import AssignDialog from "./components/AssignDialog.vue";
import Tiptap from "@/views/components/Tiptap.vue";

import Draggable from "vuedraggable";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    ProductDialog,
    OptionsDialog,
    ImageDialog,
    RelatedDialog,
    DetailDialog,
    AttributeDialog,
    AssignDialog,
    Tiptap,
    Draggable,
  },

  data() {
    return {
      index: null,
      isMain: false,
      dialogImageUrl: "",
      dialogVisible: false,
      imageList: [],
      status_msg: "",
      status: "",
      isCreatingPost: false,
      title: "",
      body: "",
      componentKey: 0,
      statuses: [
        { text: "Draft", value: "draft" },
        { text: "On Hold", value: "on-hold" },
        { text: "Online", value: "online" },
        { text: "Offline", value: "offline" },
      ],
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          to: { name: "module-craigtoun-products" },
          exact: true,
        },
      ],
      loading: false,
      loadingSale: false,
      errors: {},
      selectedAttributes: {},
      statusLoading: false,
      fields: {
        description: null,
        isEditing: false,
      },
      errorMessage: "",

      deleteImage: {
        dialog: false,
        image: {},
        type: null,
        loading: false,
      },

      deleteProductOption: {
        dialog: false,
        product: {},
        loading: false,
      },
    };
  },

  mounted() {
    this.status = this.product.status;
    this.fields.description = this.product.description;
  },

  computed: {
    groupedAttributes() {
      const groups = {};
      if (this.product.attributes) {
        this.product.attributes.forEach((attrOpt) => {
          const attr = attrOpt.attribute.name;

          if (!groups[attr]) groups[attr] = [];
          groups[attr].push(attrOpt);
        });
      }

      return groups;
    },

    imagesOther: {
      get() {
        return this.product.gallery;
      },
      set(items) {
        const displayOrder = items.map((p) => {
          return p.id;
        });
        const appId = this.$route.params.id;
        const productId = this.$route.params.productId;

        this.$store.commit("showHideLoader", true);
        this.$store
          .dispatch("craigtoun/productsStore/updateGalleryOrder", {
            appId,
            displayOrder,
            productId,
          })
          .then(() => this.$store.commit("showHideLoader", false))
          .catch(() => this.$store.commit("showHideLoader", false));
      },
    },

    product() {
      return this.$store.getters["craigtoun/productsStore/get"];
    },

    tableHeaders() {
      return [
        { text: "Customer", value: "customer" },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },

  methods: {
    handleError(error) {
      this.errorMessage = error;
    },

    openDeleteImage(image, type = null) {
      this.deleteImage.image = image;
      this.deleteImage.type = type;
      this.deleteImage.dialog = true;
    },

    resetDeleteImage() {
      this.deleteImage.dialog = false;
      this.deleteImage.image = {};
      this.deleteImage.loading = false;
    },

    saveDeleteImage() {
      this.deleteImage.loading = true;

      let payload = {
        appId: this.$route.params.id,
        imageId: this.deleteImage.image.id,
        isMain: this.deleteImage.type,
      };

      payload.productId = this.product.id;

      this.$store
        .dispatch("craigtoun/productsStore/deleteProductImage", payload)
        .then(() => {
          this.resetDeleteImage();
        })
        .catch(() => {
          this.deleteImage.loading = false;
        });
    },

    openDeleteProductAttribute(product) {
      this.deleteProductOption.product = product;
      this.deleteProductOption.dialog = true;
    },

    resetDeleteProductOption() {
      this.deleteProductOption.dialog = false;
      this.deleteProductOption.product = {};
      this.deleteProductOption.loading = false;
    },

    saveDeleteProductOption() {
      this.deleteProductOption.loading = true;

      this.$store
        .dispatch("craigtoun/productsStore/deleteProductOption", {
          appId: this.$route.params.id,
          productId: this.$route.params.productId,
          attributeId: this.deleteProductOption.product.pivot.id,
        })
        .then(() => {
          this.resetDeleteProductOption();
        })
        .catch(() => {
          this.deleteProductOption.loading = false;
        });
    },

    setMainImage(image) {
      this.isEditing = true;

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      payload.fields.type = "main-image";
      payload.fields.imageId = image.id;

      payload.productId = this.product.id;

      this.$store
        .dispatch("craigtoun/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    statusChanged: function () {
      this.statusLoading = true;
      this.isEditing = true;

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      payload.fields.type = "status";

      this.$store
        .dispatch("craigtoun/productsStore/saveProduct", payload)
        .then(() => {
          this.statusLoading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Status Updated Successfully!",
            color: "success",
          });
        })
        .catch(() => (this.statusLoading = false));
    },

    update(name) {
      this.loading = true;
      this.errors = {};
      this.isEditing = true;

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      payload.fields.type = name;

      this.$store
        .dispatch("craigtoun/productsStore/saveProduct", payload)
        .then(() => {
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Description Successfully Saved",
            color: "success",
          });
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
    },

    resetFormSale: function () {
      this.loadingSale = false;
      this.isEditing = true;
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.show-btn {
  opacity: 1;
}

.main-btn {
  display: block;
  margin-top: 5px;
  background-color: #ddd;
  border: none;
  padding: 5px;
  cursor: pointer;
}

/* .v-btn {
  opacity: 0;
  background-color: #fff !important;
  color: #000 !important;
  transition: opacity 0.4s ease-in-out;
}
.v-card .v-image {
  transition: filter 0.4s ease-in-out;
}

.v-card.on-hover .v-image {
  filter: brightness(25%);
} */
</style>
